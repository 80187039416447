import { $, $$ } from './../helpers'
import { CountUp } from 'countup.js/dist/countUp.umd'

export default class {

    constructor() {
        this.observer = new IntersectionObserver(this.handleIntersection.bind(this), {
            rootMargin: '-180px 0px'
        })
    }

    mount() {
        $$('.stat').forEach(container => {
            this.observer.observe(container)
        })
    }

    destroy() {
        // CountUp does not provide a method of destroying itself
        this.observer.disconnect()
    }

    handleIntersection(entries, observer) {
        entries.forEach(entry => {
            if ( entry.isIntersecting || entry.intersectionRatio > 0 ) {
                const { target } = entry

                // Init CountUp
                const counts = $$('[data-count]', target).map(el => this.buildCount(el))

                target.classList.add('is-inview')
                counts.forEach(count => count.start())

                // Stop observing
                observer.unobserve(target)
            }
        })
    }

    buildCount(el) {
        const figure = el.textContent
        const decimals = figure.match(/\.([\d+])/)

        return new CountUp(el, figure, {
            useGrouping: false,
            decimalPlaces: decimals ? decimals[1].length : 0
        })
    }

}
