import { $, $$, createElement } from './../helpers'
import gsap from 'gsap'
import debounce from 'lodash.debounce'

export default class {

    mount() {
        $$('.page-hero').forEach(container => {
            this.title = $('.page-hero__title')

            // Handle resize
            this.handleResize = debounce(() => this._splitText(this.title), 250)
            window.addEventListener('resize', this.handleResize)

            // Split text
            this._splitText(this.title)

            // Build animation
            this.buildAnimation()
        })
    }

    destroy() {
        // Remove resize event
        if (this.handleResize) {
            window.removeEventListener('resize', this.handleResize)
            this.handleResize = null
        }

        // // Destroy animation
        if (this.animation) {
            this.animation.kill()
        }
    }

    buildAnimation() {
        this.animation = gsap.timeline({ paused: true })
        const spans = $$('.page-hero__title > span')

        spans.forEach(span => {
            this.animation.fromTo(span, { opacity: 0, y: 10 }, { opacity: 1, y: 0, duration: (2.5 / spans.length) })
        })

        this.animation.fromTo('.page-hero__scroll', { opacity: 0 }, { opacity: 1, duration: .5 }, '=+.6')

        this.animation.eventCallback('onStart', () => {
            $$('.page-hero video').forEach(video => video.play())
            // TODO: Dispatch event to pause video when animation
        })
    }

    _splitText(el) {
        const { clientWidth } = el
        const words = el.textContent.split(/\s/)

        // Clear content
        el.innerHTML = ''

        // Create intial test node
        let testNode = createElement('span', { textContent: words.shift(), style: 'display: inline-block; white-space: pre' })
        el.appendChild(testNode)

        words.forEach(word => {
            // Cache existing text
            let { textContent } = testNode

            // Append next word
            testNode.textContent += ` ${word}`

            // Calculate new width
            let { width } = testNode.getBoundingClientRect()

            // Test width, resetting cached text and creating new node if needed
            if (width > clientWidth) {
                testNode.textContent = `${textContent} `

                testNode = createElement('span', { textContent: word, style: 'display: inline-block; white-space: pre' })
                el.appendChild(testNode)
            }
        })

        Array.from(el.children).forEach(span => span.style.display = 'block')
    }

}
