import { $, $$, createElement, delegate } from './../helpers'
import debounce from 'lodash.debounce'
import Shuffle from 'shufflejs'

export default class {

    mount() {
        this.filters = $$('.content-filter').map(el => new ContentFilter(el))
    }

    destroy() {
        this.filters.forEach(filter => filter.tearDown())
    }

}

class ContentFilter {

    constructor(el) {
        this.el = el
        this.target = $(el.getAttribute('data-target'))
        this.ignoredItems = $$('.shuffle-item--ignore', this.target)

        // UI
        this.options = $('.content-filter__options', this.el)
        this.search = $('.content-filter__search-control > input', this.el)
        this.results = $('.content-filter__search-results', this.el)

        // No results
        this.noResults = createElement('div', { className: 'content-filter__no-results', innerHTML: 'There are no results to show. Please update your search.' })
        this.options.after(this.noResults)

        // Filters
        this.filters = []

        // Initialise Shuffle
        this.shuffle = new Shuffle(this.target, {
            sizer: $('.team-grid__item', this.target)
        })

        // Bind events
        this.bindEvents()

        // Update result count
        this.updateResultCount(true)
    }

    bindEvents() {
        this.search.addEventListener('input', debounce(this.handleSearchInput.bind(this), 100))

        $$('[data-filter]', this.options).forEach(el => el.addEventListener('change', this.handleFilterChange.bind(this)))
    }

    tearDown() {
        this.shuffle.destroy()
    }

    filter() {
        if (this.filters.length < 1 && (!this.search.value || this.search.value.length <= 2)) {
            this.shuffle.filter(Shuffle.ALL_ITEMS)
            this.updateResultCount(true)
        } else {
            this.shuffle.filter(item => {
                if (!item.hasAttribute('data-filters') || item.classList.contains('shuffle-item--ignore')) {
                    return false
                }

                const itemFilters = item.getAttribute('data-filters').split(',')
                const itemSearch = item.getAttribute('data-search').split(',')

                // Test filters
                const passesFilters = this.filters.every(filter => itemFilters.includes(filter))

                if (!passesFilters) {
                    return false
                }

                // Test search
                const passesSearch = itemSearch.some(text => text.toLowerCase().includes(this.search.value.toLowerCase()))

                if (this.search.value.length > 2 && !passesSearch) {
                    return false
                }

                return true
            })

            this.updateResultCount()
        }
    }

    handleFilterChange(e) {
        this.filters = $$('[data-filter]', this.options).map(el => el.value).filter(f => f.length > 0)

        // Filter
        this.filter()
    }

    handleSearchInput() {
        this.filter()
    }

    updateResultCount(removeIgnored) {
        const count = removeIgnored ? this.shuffle.visibleItems - this.ignoredItems.length : this.shuffle.visibleItems

        this.results.innerHTML = `Showing <b>${count}</b> team ${count === 1 ? 'member' : 'members'}`
        this.noResults.style.display = count < 1 ? 'block' : 'none'
    }

}
