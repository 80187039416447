import { $, $$ } from './../helpers'
import Glide from '@glidejs/glide'

const padInt = int => String(int).padStart(2, '0')

export default class {

    constructor() {
        this.glide = false
    }

    mount() {
        $$('.team-carousel').forEach(container => {
            const el = $('.glide', container)
            const itemCount = $$('.glide__slide', el).length
            const progressThumb = $('.team-carousel__nav-thumb')
            const progressCount = $('.team-carousel__nav-count')

            // Create and mount
            this.glide = this.buildGlide(el)

            // Update progress on build and move
            this.glide.on(['build.after', 'move'], () => {
                const { glide } = this
                progressThumb.style.width = `${(glide.index + glide.settings.perView) / itemCount * 100}%`
                progressCount.innerHTML = `${padInt(glide.index + glide.settings.perView)}/${padInt(itemCount)}`
            })

            // Rewind on resize, fixing potential progress calculation issues
            this.glide.on('resize', () => this.glide.go('<<'))

            // Mount
            this.glide.mount()
        })
    }

    destroy() {
        if (this.glide) {
            this.glide.destroy()
            this.glide = false
        }
    }

    buildGlide(el) {
        return new Glide(el, {
            type: 'slider',
            rewind: false,
            bound: true,
            gap: 32,
            peek: {
                before: 0,
                after: 1
            },
            animationDuration: 600,
            animationTimingFunc: 'cubic-bezier(0, 0.55, 0.45, 1)',
            perView: 3,
            breakpoints: {
                960: {
                    perView: 2
                },
                480: {
                    perView: 1
                }
            }
        })
    }

}
