import { $, $$, createElement, delegate } from './../helpers'
import debounce from 'lodash.debounce'
import loadJs from 'fg-loadjs'
import styles from './../config/map-styles'

export default class {

    constructor() {
        this.observer = new IntersectionObserver(this.handleIntersection.bind(this))

        // Map elements
        this.map = null
        this.marker = null

        // Bind initMap callback to global scope
        window.initMap = this._initMap.bind(this)
    }

    mount() {
        $$('.contact').forEach(container => {
            this.el = container
            this.panel = $('.contact__panel', container)

            // Tabs
            const tabNav = $('.contact__tabs-list', container)
            const tabSections = $$('.contact__tabs-section', container)

            delegate(tabNav, 'a', 'click', e => {
                e.preventDefault()
                e.stopPropagation()

                // Get tab
                const tabHref = e.delegateTarget.getAttribute('href').substr(1)
                const targetTab = document.getElementById(tabHref)

                // Update
                $$('a', tabNav).forEach(a => a.removeAttribute('aria-selected'))
                e.delegateTarget.setAttribute('aria-selected', true)

                tabSections.forEach(section => section.hidden = true)
                targetTab.hidden = false

                this._updateMapLocation()
            })

            // Observe
            this.observer.observe(container)
        })
    }

    destroy() {
        this.observer.disconnect()

        this.marker = null
        this.map = null

        window.removeEventListener('resize', this.handleResize)
        this.handleResize = null
    }

    handleIntersection(entries, observer) {
        entries.forEach(entry => {
            if ( entry.isIntersecting || entry.intersectionRatio > 0 ) {
                // Load API if needed
                if (!window.google || !window.google.maps) {
                    loadJs(`https://maps.googleapis.com/maps/api/js?key=${window.GOOGLE_MAPS_API_KEY}&callback=initMap`)
                } else {
                    this._initMap()
                }

                observer.unobserve(entry.target)
            }
        })
    }

    _initMap() {
        // Prevent re-initialising the map
        if (!this.map) {
            const mapContainer = $('.js-office-map')
            const mapDiv = createElement('div')

            mapContainer.appendChild(mapDiv)

            this.map = new google.maps.Map(mapDiv, {
                zoom: 15,
                styles: styles,
                disableDefaultUI: true,
                scrollwheel: false
            })

            this.marker = new google.maps.Marker({
                map: this.map,
                icon: {
                    url: '/app/themes/arcmont/resources/assets/img/map-marker.png',
                    anchor: new google.maps.Point(48, 48),
                    scaledSize: new google.maps.Size(96, 96)
                }
            })

            // Handle resize
            this.handleResize = debounce(() => this._updateMapLocation(), 100)
            window.addEventListener('resize', this.handleResize)
        }

        // Set initial location
        setTimeout(() => this._updateMapLocation(), 10)
    }

    _updateMapLocation() {
        const [lat, lng] = this._getCurrentLocation()

        this.marker.setPosition({ lat: lat, lng: lng })
        this.map.setCenter({ lat: lat, lng: lng })

        // Calculate offset
        let { left, width } = this.panel.getBoundingClientRect()
        this.map.panBy(((window.innerWidth - (left + width)) / 2) * -1, 0)
    }

    _getCurrentLocation() {
        // Parse lat/lng from current tab
        return $('[data-location][aria-selected]').getAttribute('data-location').split(',').map(f => parseFloat(f))
    }

}
