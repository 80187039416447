import { $, $$ } from './../helpers/qsa'
import gsap from 'gsap'

export default class {

    constructor() {
        this.container = $('.site-header')
        this.input = $('.site-header__search input')

        // Animation
        this.buildAnimation()

        // Events
        $$('.js-search-toggle').forEach(el => el.addEventListener('click', this.handleToggle.bind(this)))
    }

    buildAnimation() {
        this.animation = gsap.timeline({ paused: true, reversed: true })

        this.animation.set('.site-header__search', { display: 'block', height: '100vh' })
            .fromTo('.site-header__search', { opacity: 0 }, { opacity: 1, duration: .4 })
            .fromTo('.site-header__search-form', { y: '-100%' }, { y: 0, duration: .4 }, '=-.1')

        this.animation.eventCallback('onStart', this.handleShowStart.bind(this))
        this.animation.eventCallback('onComplete', this.handleShowEnd.bind(this))
        this.animation.eventCallback('onReverseComplete', this.handleHideEnd.bind(this))
    }

    handleToggle(e) {
        const state = this.animation.reversed()

        this.animation
            .timeScale(state ? 1 : 1.75)
            .play()
            .reversed(!state)
    }

    handleShowStart() {
        this.container.classList.add('site-header--search-active')
        this.input.value = ''
        // TODO: Dispatch animation event
    }

    handleShowEnd() {
        this.input.focus()
    }

    handleHideEnd() {
        this.container.classList.remove('site-header--search-active')
        // TODO: Dispatch animation stop event
    }

}
