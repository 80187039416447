import 'lazysizes'
import 'intersection-observer'
import { $, $$ } from './helpers'
import { SiteHeader, Search, LoadingAnimation } from './modules'
import { PageHero, Parallax, Offices, StatCounter, TeamCarousel, ContentFilter } from './components'
import './modules/cookie-consent'

class App {

    constructor() {
        this.container = $('.main-content')

        this.header = new SiteHeader()
        this.search = new Search()
        this.loadingAnimation = new LoadingAnimation()

        this.components = [
            new PageHero(),
            new Parallax(),
            new Offices(),
            new StatCounter(),
            new TeamCarousel(),
            new ContentFilter()
        ]

        this.mountComponents()

        // Ignore the router?
        if (window.NO_ROUTER) {
            this.loadingAnimation.hide()

            this.scrollToFragment()
            this.animateComponents()

            return
        }

        // Initial load with animation
        this.loadingAnimation.play().then(() => {
            this.scrollToFragment()
            this.animateComponents()
        })

        // Bind to clicks
        document.body.addEventListener('click', this.handleDocumentClick.bind(this))

        // Bind to popstate
        window.addEventListener('popstate', this.handlePopstate.bind(this))
    }

    handleDocumentClick(e) {
        // Find link
        const link = e.target.closest('a')

        // Let external and admin links passed
        if (!link || link.hasAttribute('target') || link.href.includes('/wp/') || !link.href.startsWith(location.origin)) {
            return
        }

        e.preventDefault()

        // Get current path
        const currentPath = window.location.pathname

        // Update URL
        history.pushState({}, null, link.href)

        if (currentPath !== window.location.pathname) {
            // Handle next page load
            this.loadUrl(link.href)
        } else {
            this.scrollToFragment()
        }
    }

    handlePopstate(e) {
        const { href } = window.location

        this.loadUrl(href)
    }

    mountComponents() {
        this.components.forEach(component => component.mount())
    }

    animateComponents() {
        this.components.forEach(({ animation }) => animation && animation.play())
    }

    destroyComponents() {
        this.components.forEach(component => component.destroy())
    }

    loadUrl(request) {
        const url = new URL(request)

        fetch(url.pathname)
            .then(response => response.text())
            .then(html => {
                try {
                    const parser = new DOMParser()
                    const dom = parser.parseFromString(html, 'text/html')

                    // Get content
                    const content = dom.querySelector('.main-content')

                    // Destroy current page components
                    this.destroyComponents()

                    // Replace content
                    this.container.innerHTML = content.innerHTML
                    this.container.className = content.className

                    // Update page title
                    document.title = dom.title

                    // Update menu
                    this.header.updateActiveItem()

                    // Scroll to top
                    window.scrollTo(0, 0)

                    // Mount new page components
                    this.mountComponents()

                    // Scroll to anchor if required
                    this.scrollToFragment()

                    this.animateComponents()
                } catch (error) {
                    // Stay quiet
                }
            })
    }

    scrollToFragment() {
        const { hash } = window.location

        if (hash) {
            const target = document.getElementById(hash.substr(1))

            if (target) {
                target.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }

}

const app = new App()
