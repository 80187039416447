import 'cookieconsent'

cookieconsent.initialise({
    palette: {
        popup: {
            background: '#252e39'
        },
        button: {
            background: '#009CDE'
        }
    },
    position: 'bottom-right',
    content: {
        message: `
            <p>Our website uses cookies, which allow us to distinguish you from other users of our website, which helps us to provide you with a good experience when you browse our website and also allows us to improve our site.</p>
            <p>By clicking “Accept”, you agree to our use of cookies as described in our Cookie Policy.</p>
        `,
        dismiss: 'Accept',
        href: '/cookie-policy'
    }
})
