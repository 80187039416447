import { $, $$ } from './../helpers/qsa'
import { gsap, Linear, Power1 } from 'gsap'

export default class {

    constructor() {
        this.buildAnimation()
    }

    buildAnimation() {
        this.animation = gsap.timeline({ paused: true })

        const icon = $('.loading-overlay__icon')

        $$('path', icon).forEach(path => {
            this.animation.to(path, { strokeDashoffset: 0, duration: .7, ease: Linear.easeNone }, '-=.2')
        })

        this.animation.to(icon, { opacity: 0, y: 10, duration: .4 }, '+=1.2')
        this.animation.fromTo('.site-header', { opacity: 0, y: 20 }, { opacity: 1, y: 0, duration: .4 }, '-=.1')
        this.animation.to('.loading-overlay', { opacity: 0, duration: .8, ease: Power1.out }, '-=.2')
        this.animation.set('.loading-overlay', { display: 'none' })
        // TODO: Investigate why delay property isn't working on timeline

    }

    play() {
        return this.animation.play()
    }

    hide() {
        this.animation.progress(1, true)
    }

}
