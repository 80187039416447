import { $, $$ } from './../helpers'
import Rellax from 'rellax'

export default class {

    constructor() {
        this.rellax = false
    }

    mount() {
        $$('.parallax').forEach(container => {
            const bg = $('.parallax__bg', container)

            this.rellax = this.buildRellax(bg)
        })
    }

    destroy() {
        if (this.rellax) {
            this.rellax.destroy()
            this.rellax = false
        }
    }

    buildRellax(el) {
        return new Rellax(el, {
            speed: 20
        })
    }

}
