import { $, $$ } from './../helpers/qsa'
import gsap from 'gsap'

export default class {

    constructor() {
        this.el = $('.site-header')

        // Scroll
        this.scrollTick = false
        window.addEventListener('scroll', this.handleScroll.bind(this))

        this.handleScroll()

        // Build animation
        this.animationMatchMedia = window.matchMedia('(max-width: 899px)')
        this.animationMatchMedia.addListener(this.handleMediaQueryChange.bind(this))

        this.handleMediaQueryChange(this.animationMatchMedia)

        // Menu toggles
        $$('.js-menu-toggle').forEach(el => el.addEventListener('click', this.handleMenuToggle.bind(this)))
        $$('.js-subnav-toggle', this.el).forEach(el => el.addEventListener('click', this.handleSubNavToggle.bind(this)))

        // Close menu on click
        $$('.site-header__menu a', this.el).forEach(el => el.addEventListener('click', this.handleMenuItemClick.bind(this)))
    }

    buildAnimation() {
        this.animation = gsap.timeline({ paused: true, reversed: true })

        this.animation.set('.site-header__menu-container', { display: 'block' })
            .fromTo('.site-header__menu-bg', { opacity: 0 }, { opacity: 1, duration: .6 })
            .fromTo('.site-header__menu', { x: '-100%' }, { x: 0, duration: .4 }, '=-.2')

        $$('.site-header__nav-item', this.el).forEach(item => {
            this.animation.fromTo(item, { opacity: 0, y: 30 }, { opacity: 1, y: 0, duration: .3 }, '=-.1')
        })

        this.animation.fromTo('.site-header__menu-social', { opacity: 0, y: 15 }, { opacity: 1, y: 0, duration: .3 })
            .fromTo('.site-header__menu-legal', { opacity: 0, y: 15 }, { opacity: 1, y: 0, duration: .3 })

        this.animation.eventCallback('onStart', () => {
            this.el.classList.toggle('site-header--menu-active')
            // TODO: Dispatch animation event
        })

        this.animation.eventCallback('onReverseComplete', () => {
            this.el.classList.toggle('site-header--menu-active')
            // TODO: Dispatch animation stop event
        })
    }

    destroyAnimation() {
        if (this.animation) {
            const animationTargets = this.animation.getChildren().flatMap(c => c._targets)
            this.animation.progress(1, true).set(animationTargets, { clearProps: 'all' }).kill()

            this.el.classList.remove('site-header--menu-active')
        }
    }

    handleMediaQueryChange(e) {
        if (e.matches) {
            this.buildAnimation()
        } else {
            this.destroyAnimation()
        }
    }

    handleMenuToggle(e) {
        if (this.animation) {
            // Get reversed state
            const state = this.animation.reversed()

            this.animation
                .timeScale(state ? 1 : 1.75)
                .play()
                .reversed(!state)
        }
    }

    handleSubNavToggle(e) {
        const button = e.currentTarget
        button.setAttribute('aria-expanded', button.getAttribute('aria-expanded') === 'true' ? false : true)
    }

    handleMenuItemClick(e) {
        // Blur
        e.currentTarget.blur()

        // Close menu
        if (this.animationMatchMedia.matches) {
            this.handleMenuToggle()
        }
    }

    handleScroll() {
        if (this.scrollTick) {
            return
        }

        window.requestAnimationFrame(() => {
            this.setScrollStatus()
            this.scrollTick = false
        })

        this.scrollTick = true
    }

    setScrollStatus() {
        this.el.classList.toggle('site-header--scrolled', window.scrollY > 50)
    }

    updateActiveItem(href = window.location.href) {
        $$('.current-menu-item', this.el).forEach(item => item.classList.remove('current-menu-item'))
        $$(`a[href="${href}"]`, this.el).forEach(({ parentNode }) => parentNode.classList.add('current-menu-item'))
    }

}
